<template>
    <section>

        <progress value="0" max="1">
            <div class="progress-container">
                <span class="progress-bar"></span>    
            </div>
        </progress>

        <div class="story" v-if="data">
            <div class="story__header">

                <div class="image" :style="'background-image: url(' + data.bgImage.scr + ')'"></div>

                <div class="story__header__date">
                    <p class="month">{{ data.date.month }}</p>
                    <p class="day">{{ data.date.day }}</p>
                    <p class="year">{{ data.date.year }}</p>
                </div>

                <div class="story__header__share">
                    <p>SHARE</p>
                    <div class="circles">
                        <a :href="twShareURL" target="_blank">
                            <div class="circle tw">
                                <svg role = "img" aria-label = "Twitter share icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.43 73.32">
                                    <path class="cls-1" d="M81.23,18.17v2.35c0,24.59-18.59,52.8-52.8,52.8A51.63,51.63,0,0,1,0,65a32.14,32.14,0,0,0,4.49.21,37.69,37.69,0,0,0,23.09-7.91A18.7,18.7,0,0,1,10.26,44.46a18.18,18.18,0,0,0,3.42.43,18.17,18.17,0,0,0,4.92-.64,18.57,18.57,0,0,1-15-18.17v-.21A19,19,0,0,0,12,28.22,18.84,18.84,0,0,1,3.63,12.83,17.78,17.78,0,0,1,6.2,3.42,52.8,52.8,0,0,0,44.46,22.87,26.75,26.75,0,0,1,44,18.6,18.6,18.6,0,0,1,76.1,5.77,37,37,0,0,0,87.86,1.28a19.34,19.34,0,0,1-8.12,10.26,35.75,35.75,0,0,0,10.69-3A35.78,35.78,0,0,1,81.23,18.17Z" />
                                </svg>
                            </div>
                        </a>
                        <a :href="fbShareURL" target="_blank">
                            <div class="circle fb">
                                <svg role = "img" aria-label = "Facebook share icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.43 93.32">
                                    <path class="cls-1" d="M41.81,46.66H28.64V93.32H9.24V46.66H0V30.26H9.24V19.63C9.24,12,12.94,0,28.87,0H43.2V15.94H32.8a4,4,0,0,0-4.16,4.39V30H43.43Z" />
                                </svg>
                            </div>
                        </a>

                    </div>
                </div>

            </div>

            <div class="story__header__caption">
                <p class="author">{{ data.author }}</p>
                <p class="title">{{ data.title }}</p>
            </div>

            <div v-for="(block, i) in data.blocks" :id="block.id" v-scroll-reveal>

                <div class="story__copy-block" v-html="block.content" v-if="block.type == 'copy'"></div>

                <!-- Gallery Carousel -->
                <image-gallery class="story__gallery" v-if="block.type == 'gallery'" :slides="block.slides" />

                <!-- Image -->
                <div class="story__image-block" v-if="block.type == 'image'" :class="{ center : block.assets[0].alignment == 'center'}">
                    <router-link :to="{ name: 'storiesMediaModal', params: { mediaId: block.assets[0].id } }" append @click.native="modalAssets=block.assets">
                        <img :src="block.assets[0].thumb.src" :alt="block.assets[0].thumb.alt" :class="{ center : block.assets[0].alignment == 'center'}">
                    </router-link>
                    <div class="caption" v-html="block.caption"></div>
                </div>

                <!-- Text Wrap Image -->
                <div :class="`story__text-wrap-img ${block.imagePosition}`" v-if="block.type == 'textWrapImage'">
                    <img :src="block.imagePath" alt="Story detail image">
                    <div class="copy" v-html="block.copy"></div>
                </div>

                <!-- Quote -->
                <div class="story__quote" v-if="block.type == 'quote'">
                    <div class="story__quote__copy">{{ block.copy }}</div>
                    <div class="attribution">{{ block.attribution }}</div>
                    <div class="role">{{ block.role }}</div>
                </div>

                <!-- Image Grid -->
                <div class="story__multi-image" v-if="block.type == 'imageGrid'">
                    <div class="images">
                        <div v-for="(image, i) in block.assets" :key="i">
                            <router-link :to="{ name: 'storiesMediaModal', params: { mediaId: image.id } }" append @click.native="modalAssets=block.assets">
                                <img :src="image.thumb.src" :alt="image.thumb.alt">
                            </router-link>
                        </div>
                    </div>
                    <div class="caption" v-html="block.caption"></div>
                </div>

                <!-- Video -->
                <div class="story__video-block" v-if="block.type == 'video'">
                    <router-link :to="{ name: 'storiesMediaModal', params: { mediaId: block.assets[0].id } }" append @click.native="modalAssets=block.assets">
                        <div class="story__video-block__wrapper">
                            <div class="thumb" :style="{ 'background-image': 'url(' + block.path + ')' }" role="img" :alt="block.alt_text"></div>
                            <div class="overlay">
                                <img src="../assets/home/scene1/icon-play.svg" alt = "Video play icon">
                                <h4 v-html="block.caption"></h4>
                            </div>
                        </div>
                        <!-- <p class="caption">{{ block.caption }}</p> -->
                    </router-link>
                </div>

                <!-- html block -->
                <div class="story__html-block" v-if="block.type == 'html'" v-html="getHtmlBlockContent(block.content)">

                </div>

            </div>

            <div class="story__top-line">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="bar bar--right"></div>
            </div>

            <div class="story__more-news">

                <div class="story__more-news__header">
                    <p><span>More</span> Stories</p>
                </div>

                <div class="story__more-news__stories">

                    <!-- <router-link v-for="(story, i) in data.news" :key="story.id" :to="story.slug"
                    append :class="['stories__news-mobile__article', { 'first': i === 0 }]"> -->

                    <router-link v-for="(story, i) in data.news" :key="story.id" :to="`/stories/${story.slug}`" append :class="['stories__news-mobile__article', { 'first': i === 0 }]">

                        <!-- <router-link v-for="(story, i) in data.news" :key="story.id" :to="story.slug" append class="stories__news-mobile__article"> -->
                        <div class="image" :style="'background-image: url(' + story.asset.path + ')'" v-if="story.asset.type == 'image'"></div>
                        <div class="video" v-else>
                            <div class="video-wrap">
                                <video autoplay muted loop playsinline>
                                    <source type="video/mp4" :src="story.asset.path">
                                </video>
                            </div>
                        </div>
                        <div class="caption">
                            <div class="top">
                                <p class="date-numeric">{{ story.date.numeric }}</p>
                                <p class="author">{{ story.author }}</p>
                            </div>
                            <!-- 75 char max -->
                            <p class="title">{{ story.title }}</p>
                        </div>
                    </router-link>
                </div>

                <router-link to="/stories">
                    <diamond-button>ALL STORIES</diamond-button>
                </router-link>
            </div>

            <div class="story__bottom-line">
                <div class="bar bar--right"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>

            <div v-if="data.featuredJob" class="story__featured-job-opening">
                <div class="story__more-news__header">
                    <p><span>Featured</span> Job Opening</p>
                </div>
                <div class="category__openings__opening">
                    <!-- <router-link :to="'/careers/' + data.featuredJob.slug" append> -->
                    <!-- <router-link :to="{path: '/careers/' + data.featuredJob.slug}">  -->
                    <router-link :to="`/careers/${data.featuredJob.category}/${data.featuredJob.slug}`">

                        <div class="job-wrapper">
                            <img :src="data.featuredJob.icon" alt="">

                            <div class="job-wrapper__description">
                                <div class="position">{{ data.featuredJob.position }}</div>

                                <div class="details">
                                    <div class="type">{{ data.featuredJob.type }}</div>
                                    <div class="posted">Posted: {{ data.featuredJob.date }}</div>
                                </div>

                            </div>

                            <div class="see-all">
                                <div class="openings">
                                    <p class="numberOfOpenings">{{ data.featuredJob.totalPositions }}</p>
                                    <p>openings</p>

                                </div>

                                <div class="apply-today">
                                    <router-link to="/careers">
                                        <div class="btn btn--red-outline">
                                            <span v-html="data.featuredJob.buttonMessage"></span>
                                        </div>
                                    </router-link>
                                </div>

                            </div>
                        </div>

                        <DiamondButton>VIEW THIS JOB</DiamondButton>
                    </router-link>
                </div>
            </div>

            <div v-if="data.featuredJob"  class="story__bottom-line">
                <div class="bar bar--right"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>

            <transition name="fade">
                <router-view parentName="stories-detail" :data="modalAssets" />
            </transition>

        </div>

        <div class="preload-height" v-else></div>

    </section>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import ImageGallery from '../components/ImageGallery.vue'
    import SlickCarousels from '@/helpers/SlickCarousels.js'
    import DiamondButton from '../components/buttons/DiamondButton'
    import Meta from '../mixins/Meta.js'

    export default {
        mixins: [Meta],
        components: {
            DiamondButton,
            ImageGallery
        },
        data() {
            return {
                data: null,
                modalAssets: null
            }
        },
        mounted() {
            $(window).scrollTop(0);
            this.loadData();
            this.onJsonLoaded();
            this.$body.addClass('stories-detail');
        },
        methods: {
            loadData() {
                axios.get(this.$json('/data/stories/' + this.$route.params.id + '.json'))

                    // axios.get('/data/news/how-six-students.json')

                    .then(response => {
                        this.data = response.data;
                        this.setMetaData(this.data.meta);
                        // console.log(this.title, this.description);
                        
                        Vue.nextTick(() => {
                            this.setModalDataIfShowingModal();
                        });
                        this.$checkAgeGate(this.data.ageGate);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            setModalDataIfShowingModal() {

                // if deeplinking to media modal, need to trigger click
                // event so current mediaModal assets are set properly
                if (this.$route.params.mediaId) {
                    $('a[href="' + this.$route.path + '"]').get(0).click();
                }

            },
            onJsonLoaded() {
                // setTimeout(() => {
                //     this.$store.commit('endSectionPreload');
                // }, 500);

                SlickCarousels.init();

            },
            getHtmlBlockContent(content) {
                this.executeScripts(content);
                return content;
            },
            executeScripts(html) {

                var $scripts = $('<div>' + html + '</div>').find('script');
                var scripts = $.makeArray( $scripts );

                for (let i = 0; i < scripts.length; i++) {
                    let script = document.createElement('script');
                    script.type = scripts[i].type || 'text/javascript';
                    if (scripts[i].hasAttribute('src')) script.src = scripts[i].src;
                    script.innerHTML = scripts[i].innerHTML;
                    document.head.appendChild(script);
                    document.head.removeChild(script);
                }

            },
            topScrollBar() {

                $(document).ready(function() {

                    var getMax = function() {
                        return $(document).height() - $(window).height();
                    }

                    var getValue = function() {
                        return $(window).scrollTop();
                    }

                    if ('max' in document.createElement('progress')) {
                        var progressBar = $('progress');
                        
                        progressBar.attr({
                            max: getMax()
                        });

                        $(document).on('scroll', function() {
                            progressBar.attr({
                                value: getValue()
                            });
                        });

                        $(window).resize(function() {
                            
                            progressBar.attr({
                                max: getMax(),
                                value: getValue()
                            });
                        });

                    } else {

                        var progressBar = $('.progress-bar'),
                            max = getMax(),
                            value, width;

                        var getWidth = function() {
                            
                            value = getValue();
                            width = (value / max) * 100;
                            width = width + '%';
                            return width;
                        }

                        var setWidth = function() {
                            progressBar.css({
                                width: getWidth()
                            });
                        }

                        $(document).on('scroll', setWidth);
                        $(window).on('resize', function() {
                            
                            max = getMax();
                            setWidth();
                        });
                    }
                });
            }
        },
        computed: {
            fbShareURL() {
                return "https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(window.location.href)
            },
            twShareURL() {
                return "https://twitter.com/share?url=" + encodeURI(window.location.href)
            }
        },
        watch: {
            '$route'(to, from) {
                if (to.name == 'stories-detail') {
                    $(window).scrollTop(0);
                    this.loadData();
                }
            }
        },
        beforeMount () {
            window.addEventListener('scroll', this.topScrollBar);
        },

        beforeDestroy() {
            this.$body.removeClass('stories-detail');
            window.removeEventListener('scroll', this.topScrollBar);
        }
    }
</script>